import React, { useState } from 'react'
import axios from 'axios'

function FileUpload({ setFormData, takenData }) {
  const [selectedCoverImage, setSelectedCoverImage] = useState(null)
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([])
  const [uploading, setUploading] = useState(false)
  const [message, setMessage] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)

  const handleFileUpload = async (selectedFiles, isCoverImage) => {
    try {
      setMessage('')
      setUploading(true)

      const formData = new FormData()

      // Append the cover image with a timestamp as part of the filename
      if (isCoverImage) {
        const coverImageFilename = `${selectedFiles[0].name}`
        // const coverImageFilename = `${Date.now()}-${selectedFiles[0].name}`
        formData.append('coverImage', selectedFiles[0], coverImageFilename)
      } else {
        // Append each gallery image with a timestamp as part of the filename
        selectedFiles.forEach((image) => {
          const galleryImageFilename = `${image.name}`
          // const galleryImageFilename = `${Date.now()}-${image.name}`
          formData.append('galleryImages', image, galleryImageFilename)
        })
      }
      // Check if the token exists in localStorage
      const token = localStorage.getItem('token')

      console.log('selectedCoverImage', selectedCoverImage)
      console.log('selectedGalleryImages', selectedGalleryImages)
      console.log('form data', formData)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/file-upload/image-upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}` // Add the token as a bearer token
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setUploadProgress(percentCompleted)
        }
      })

      setMessage(response.data.message)
      const updatedFormData = { ...takenData }

      if (response.data.coverImageName !== null) {
        updatedFormData.coverImage = response.data.coverImageName
      }

      // Only add newly uploaded gallery images to the array
      if (response.data.savedGalleryImageNames.length > 0) {
        updatedFormData.images = [...takenData.images, ...response.data.savedGalleryImageNames]
      }

      setFormData(updatedFormData)

      setSelectedCoverImage(null)
      setSelectedGalleryImages([])
    } catch (error) {
      setMessage('Error uploading files.')
      console.error(error)
    } finally {
      setUploading(false)
    }
  }

  const handleFileRemove = async (filename, isCoverImage) => {
    if (isCoverImage) {
      setFormData({
        ...takenData,
        coverImage: null
      })
    } else {
      setFormData({
        ...takenData,
        images: takenData?.images?.filter((image) => image !== filename)
      })
    }
  }

  const handleCoverImageChange = (event) => {
    setSelectedCoverImage(event.target.files[0])

    handleFileUpload(Array.from(event.target.files), true)
  }

  const handleGalleryImagesChange = (event) => {
    const selectedImages = Array.from(event.target.files)

    setSelectedGalleryImages(selectedImages)
    handleFileUpload(selectedImages, false)
  }

  return (
    <div>
      <div key={'cover'} className="w-full my-5">
        <label htmlFor="" className="block">
          Product Cover Image <span className="text-red-600">*</span>
        </label>
        <input
          type="file"
          name="coverImage"
          className="block w-full form-input rounded-md border-gray-500"
          autoComplete="off"
          placeholder="Enter keywords here, separated by commas"
          accept="image/*"
          onChange={handleCoverImageChange}
        />
        <div className="my-2.5">
          <h3 className=" ">Preview</h3>
          {takenData.coverImage ? (
            <div className="relative w-56 h-40">
              <img
                src={process.env.REACT_APP_API_URL + '/images/' + takenData.coverImage}
                className="h-full w-full rounded-md "
                alt={takenData.coverImage}
              />
              <button
                onClick={() => {
                  handleFileRemove(selectedCoverImage, true)
                }}
                className="absolute p-1 bg-red-500 rounded-full h-5 w-5 flex items-center justify-center border-black -top-2.5 -right-2.5"
              >
                x
              </button>
            </div>
          ) : (
            <p>No image selected</p>
          )}
        </div>
      </div>
      <div key={'gallery'} className="w-full my-5">
        <label htmlFor="" className="block">
          Multiple Images for image gallery of the product
        </label>
        <input
          type="file"
          name="images"
          className="block w-full form-input rounded-md border-gray-500"
          autoComplete="off"
          placeholder="Enter keywords here, separated by commas"
          accept="image/*"
          multiple={true}
          onChange={handleGalleryImagesChange}
        />
        <div className="my-2.5">
          <h3 className=" ">Preview</h3>
          {takenData.images.length > 0 ? (
            <div className="flex flex-wrap gap-3">
              {takenData?.images?.map((image, i) => (
                <div className="relative" key={'image-' + i + image}>
                  <img
                    src={process.env.REACT_APP_API_URL + '/images/' + image}
                    className="h-32 rounded-md  w-36"
                    alt={image}
                  />
                  <button
                    onClick={() => handleFileRemove(image)}
                    className="absolute p-1 bg-red-500 rounded-full h-5 w-5 flex items-center justify-center border-black -top-2.5 -right-2.5"
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p>No image selected</p>
          )}
        </div>
        {uploading && (
          <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
            <div
              className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: uploadProgress + '%' }}
            >
              {' '}
              {uploading && <p>Uploading... {uploadProgress}%</p>}
            </div>
          </div>
        )}

        {message && <p className="text-green-500 text-center">{message}</p>}
      </div>
    </div>
  )
}

export default FileUpload
