import { useEffect, useState } from 'react'
import createAxiosInstance from '../../action/apiConfig'
function MultiImageEdit({ formData, image, id, index, setrerender, trigger, setimages, duplicates }) {
  const oldImage = image
  const extension = `.${oldImage.split('.').splice(-1)}`
  const [error, seterror] = useState(null)
  const [Image, setImage] = useState(image.split('.').slice(0, -1).join('.'))
  const [addon, setaddon] = useState('')
  const axiosInstance = createAxiosInstance()
  const btnArray = [
    { name: 'Exterior View', value: 'exterior-view-of-' },
    { name: 'Interior View', value: 'interior-view-of-' },
    { name: 'Balcony View', value: 'balcony-view-of-' },
    { name: 'Living Room', value: 'living-room-of-' },
    { name: 'Bed Room', value: 'bed-room-of-' },
    { name: 'Bath Room', value: 'bath-room-of-' },
    { name: 'Dining Room', value: 'dining-room-of-' },
    { name: 'Kids Room', value: 'kids-room-of-' },
    { name: 'Lobby', value: 'lobby-of-' },
    { name: 'Kitchen', value: 'kitchen-of-' },
    { name: 'Park', value: 'park-of-' },
    { name: 'Swimming Pool', value: 'swimming-pool-of-' },
    { name: 'Gym', value: 'gym-of-' }
  ]
  // console.log(Image)

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      const res = await axiosInstance.post('/file-upload/image-rename', {
        oldName: oldImage,
        newName: `${Image}${extension}`
      })
      if (res.status === 200 && res.data.status === 'renamed') {
        try {
          const imageList = formData.images
          imageList[index] = res.data.newName
          const data = { ...formData, images: imageList }
          const response = await axiosInstance.put(`/products/edit/${id}`, data)
          if (response.status === 200 && response.data.message === 'Product updated successfully') {
            // navigate(0)
            setrerender((st) => !st)
          }
        } catch (error) {
          console.log('Error while updating product:', { error })
          const res = await axiosInstance.post('/file-upload/image-rename', {
            newName: oldImage,
            oldName: `${Image}${extension}`
          })
        }
      }
    } catch (error) {
      seterror(error.response.data.message)
      setTimeout(() => {
        seterror(null)
      }, 3000)
      console.log(error)
    }
  }
  useEffect(() => {
    if (trigger > 0) {
      setaddon('')
      setImage(`${formData.slug}-${index + 1}`)
    }
  }, [trigger])
  useEffect(() => {
    setimages(index, `${Image}${extension}`)
  }, [Image])

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      {error && <p className="fixed bottom-[10px] right-[30px] py-2 px-4 bg-red-500 text-white">{error}</p>}
      <div className="flex gap-2 mt-5 w-full">
        <div className="relative w-56 min-w-56 h-40">
          <img
            src={process.env.REACT_APP_API_URL + '/images/' + oldImage}
            className="h-full w-full rounded-md "
            alt={oldImage}
          />
        </div>
        <div className="w-full">
          <label htmlFor="" className="block">
            ({index + 1}) Gallery Image Name:-{' '}
            <span style={{ overflowWrap: 'anywhere' }} className="text-red-600">
              {oldImage}
            </span>
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              name="type"
              className={`block w-full min-w-52 rounded-md border-gray-500 ${duplicates?.includes(`${Image}${extension}`) ? 'outline outline-red-500 text-red-500' : ''}`}
              autoComplete="off"
              placeholder="Enter the name"
              value={Image}
              onChange={(e) => {
                setImage(e.target.value)
                // setimages(index, `${e.target.value}${extension}`)
              }}
            />
            <span>{extension}</span>
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-700 px-4 flex py-2 text-center rounded-md justify-center uppercase font-bold text-white"
            >
              Rename
            </button>
          </div>
          <div className="mt-2 flex gap-2 w-full flex-wrap">
            {btnArray.map((m, i) => (
              <span
                onClick={() => {
                  if (addon === '') {
                    setaddon(() => m.value)
                    setImage((st) => m.value + st)
                  } else {
                    setaddon(() => m.value)
                    setImage(m.value + Image.split(addon)[1])
                  }
                }}
                key={i}
                className="bg-red-500 hover:cursor-pointer hover:bg-black px-2 flex py-1 text-center rounded-md justify-center uppercase text-[13px] font-semibold text-white"
              >
                {m.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </form>
  )
}
export default MultiImageEdit
