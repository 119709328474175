import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_URL // Replace with your actual API URL

const forLogin = () => {
  const headers = {
    'Content-Type': 'application/json' // Set the appropriate content type
  }

  return axios.create({
    baseURL: BASE_URL,
    headers
  })
}

export default forLogin
