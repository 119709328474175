// ListBlogs.js
import React, { useEffect, useState } from 'react'
import createAxiosInstance from '../action/apiConfig'
import { useNavigate } from 'react-router-dom'

const baseUrl = process.env.REACT_APP_API_URL
function ListBlogs() {
  const [blogs, setBlogs] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const fetchBlogs = async () => {
    try {
      const response = await createAxiosInstance().get(`/blog/all`)
      setBlogs(response.data)
      console.log(response)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching blog data:', error)
      setLoading(false)
    }
  }

  const deleteBlog = (id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      createAxiosInstance()
        .delete(`/blog/delete/${id}`)
        .then((response) => {
          console.log(response.data)
          if (response.data.message === 'Product deleted successfully') {
            // Remove the deleted item from the data state
            setBlogs((prevData) => prevData.filter((item) => item._id !== id))
          }
        })
    } else {
      return
    }
  }

  useEffect(() => {
    fetchBlogs()
  }, [])

  return (
    <div className="w-full mx-auto ">
      <h1 className="text-3xl font-bold text-center">List of Blog Posts</h1>
      {loading ? (
        <p>Loading...</p>
      ) : blogs && Array.isArray(blogs) ? (
        <div>
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="py-4 grid lg:grid-cols-7 sm:grid-cols-3 grid-cols-2 border bottom-1 border-black p-2 gap-2 my-2 rounded-lg"
            >
              <h2>
                <span className="font-extrabold my-2">Title </span>
                <br />
                {blog.title}
              </h2>
              <p>
                <span className="font-extrabold my-2">Author</span>
                <br />
                {blog.postedBy}
              </p>
              <p>
                <span className="font-extrabold my-2">Category</span>
                <br />
                {blog.categories}
              </p>
              <p>
                <span className="font-extrabold my-2">Likes</span>
                <br />
                {blog.likes}
              </p>
              <p>
                <span className="font-extrabold my-2">Comments</span>
                <br /> {blog.comments.length}
              </p>
              <div>
                <span className="font-extrabold">Image</span>
                <img
                  src={baseUrl + '/blog/serveimage?imagekey=' + blog.coverImage.split('/').pop()}
                  alt="Blog Cover"
                  className="w-40 h-30"
                />
              </div>
              <div className="flex flex-col gap-6 h-full justify-center">
                <button
                  className="bg-blue-500 w-20 h-10 text-white rounded-lg hover:bg-blue-700"
                  onClick={() => deleteBlog(blog._id)}
                >
                  Delete
                </button>
                <button
                  className="bg-blue-500 w-20 h-10 text-white rounded-lg hover:bg-blue-700"
                  onClick={() => navigate(`/edit-blog/${blog.slug}`)}
                >
                  Edit
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No blogs found.</p>
      )}
    </div>
  )
}

export default ListBlogs
