export const COUNTRIES = ['Canada', 'USA', 'Panama', 'Mexico']
export const STATES = {
  Canada: ['Alberta', 'Ontario', 'Quebec'],
  USA: ['Florida', 'New York', 'Texas'],
  Panama: ['Panama Province'],
  Mexico: ['Quintana Roo']
}
export const CITIES = {
  Alberta: ['Calgary', 'Chestermere', 'Edmonton', 'Innisfail', 'St. Alberta', 'Rocky View', 'Airdrie'],
  Ontario: [
    'Toronto',
    'Ajax',
    'Amherstview',
    'Aurora',
    'Barrie',
    'Baxter',
    'Belleville',
    'Bowmanville',
    'Bracebridge',
    'Bradford',
    'Bradford West Gwillimbury',
    'Brampton',
    'Brant',
    'Brantford',
    'Breslau',
    'Burlington',
    'Bolton',
    'Caledon',
    'Caledonia',
    'Cambridge',
    'Clarence Rockland',
    'Cobourg',
    'Collingwood',
    'Courtice',
    'Dufferin County',
    'East Gwillimbury',
    'Erin',
    'Etobicoke',
    'Fergus',
    'Georgina',
    'Glen Park',
    'Greater Sudbury',
    'Grimsby',
    'Guelph',
    'Haldimand County',
    'Halton Hills',
    'Hamilton',
    'Hillsdale',
    'Huntsville',
    'Innisfil',
    'Kawartha Lakes',
    'King City',
    'Kingston',
    'Kitchener',
    'Kleinburg',
    'Lincoln',
    'Lindsay',
    'London',
    'Markdale',
    'Markham',
    'Midland',
    'Milton',
    'Mississauga',
    'New Tecumseth',
    'Newmarket',
    'Niagara Falls',
    'Norfolk County',
    'North Bay',
    'North York',
    'Oakville',
    'Odessa',
    'Orillia',
    'Oshawa',
    'Ottawa',
    'Owen Sound',
    'Paris',
    'Pelham',
    'Pembroke',
    'Pickering',
    'Port Colborne',
    'Port Severn',
    'Prince Edward',
    'Prince Edward County',
    'Richmond Hill',
    'Scarborough',
    'Schomberg',
    'Sebringville',
    'Shelburne',
    'Simcoe',
    'Stratford',
    'Springwater',
    'Stratford',
    'St. Catharines',
    'The Blue Mountains',
    'Thornhill',
    'Thorold',
    'Uxbridge',
    'Vaughan',
    'Wasaga Beach',
    'Waterloo',
    'Welland',
    'Whitby',
    'Whitchurch Stouffville',
    'Woodstock',
    'York',
    'Concord',
    'Woodbridge',
    'Unionville',
    'Stoney Creek',
    'Tottenham',
    'Vineland Station',
    'Kanata',
    'Oro-Medonte',
    'King',
    'Rockland',
    'East York',
    'Maple',
    'Richmond'
  ],
  Quebec: ['Montreal'],
  Florida: [
    'Miami',
    'Aventura',
    'Cape Coral',
    'Bal Harbour',
    'Bay Harbor Islands',
    'Clearwater',
    'Coral Springs',
    'Davie',
    'Fort Lauderdale',
    'Gainesville',
    'Hallandale Beach',
    'Hialeah',
    'Hollywood',
    'Jacksonville',
    'Lakeland',
    'Miami Beach',
    'Miami Gardens',
    'Miramar',
    'Northbay Village',
    'Orlando',
    'Palm Bay',
    'Pembroke Pines',
    'Plantation',
    'Pompano Beach',
    'Port St. Lucie',
    'St. Petersburg',
    'Sunny Isles Beach',
    'Sunrise',
    'Tallahassee',
    'Tampa',
    'West Palm Beach'
  ],
  'New York': ['Brooklyn', 'NewYork', 'Long Island City', 'Astoria', 'Clinton Hill'],
  Texas: [
    'Abilene',
    'Addison',
    'Aledo',
    'Allen',
    'Alvarado',
    'Alvin',
    'Anahuac',
    'Angleton',
    'Anna',
    'Arcola',
    'Argyle',
    'Arlington',
    'Atascocita',
    'Aubrey',
    'Austin',
    'Azle',
    'Balch Springs',
    'Barrett',
    'Barton Creek',
    'Bastrop',
    'Baytown',
    'Bear Creek Ranch',
    'Beasley',
    'Beaumont',
    'Bedford',
    'Bellville',
    'Belton',
    'Benbrook',
    'Blue Ridge',
    'Boerne',
    'Boyd',
    'Brenham',
    'Briarcliff',
    'Brookshire',
    'Brushy Creek',
    'Bryan',
    'Buda',
    'Bulverde',
    'Burleson',
    'Burnet',
    'Caddo Mills',
    'Canton',
    'Canyon Lake',
    'Castroville',
    'Cedar Creek',
    'Cedar Hill',
    'Cedar Park',
    'Celina',
    'Chandler',
    'China',
    'Cibolo',
    'Cleburne',
    'Cleveland',
    'Clute',
    'College Station',
    'Colleyville',
    'Commerce',
    'Conroe',
    'Converse',
    'Copper Canyon',
    'Copperas Cove',
    'Corinth',
    'Corpus Christi',
    'Corsicana',
    'Crandall',
    'Cresson',
    'Crosby',
    'Cross Timber',
    'Crowley',
    'Cut And Shoot',
    'Dallas',
    'Dayton',
    'Decatur',
    'Denison',
    'Denton',
    'Desoto',
    'Dickinson',
    'Dripping Springs',
    'Edinburg',
    'El Lago',
    'El Paso',
    'Elgin',
    'Elmendorf',
    'Ennis',
    'Euless',
    'Fair Oaks Ranch',
    'Farmersville',
    'Fate',
    'Ferris',
    'Floresville',
    'Flower Mound',
    'Forney',
    'Fort Worth',
    'Fredericksburg',
    'Fresno',
    'Friendswood',
    'Frisco',
    'Fulshear',
    'Galveston',
    'Garden Ridge',
    'Garland',
    'Georgetown',
    'Geronimo',
    'Glenn Heights',
    'Godley',
    'Granbury',
    'Grand Prairie',
    'Grandview',
    'Grangerland',
    'Greenville',
    'Haltom City',
    'Harker Heights',
    'Haslet',
    'Heartland',
    'Heath',
    'Helotes',
    'Hitchcock',
    'Horizon City',
    'Houston',
    'Howardwick',
    'Howe',
    'Hudson Bend',
    'Humble',
    'Huntsville',
    'Hutchins',
    'Hutto',
    'Iowa Colony',
    'Irving',
    'Jarrell',
    'Jonestown',
    'Josephine',
    'Joshua',
    'Jourdanton',
    'Justin',
    'Katy',
    'Kaufman',
    'Keene',
    'Keller',
    'Kemah',
    'Kennedale',
    'Kerrville',
    'Killeen',
    'Knollwood',
    'Krugerville',
    'Krum',
    'Kyle',
    'La Marque',
    'La Porte',
    'La Vernia',
    'Lago Vista',
    'Lakeway',
    'Lancaster',
    'Lantana',
    'Laredo',
    'Lavon',
    'League City',
    'Leander',
    'Lewisville',
    'Liberty Hill',
    'Little Elm',
    'Live Oak',
    'Lockhart',
    'Lone Oak',
    'Lorena',
    'Lowry Crossing',
    'Lubbock',
    'Lytle',
    'Magnolia',
    'Manchaca',
    'Manor',
    'Mansfield',
    'Manvel',
    'Marble Falls',
    'McKinney',
    'McLendon Chisholm',
    'McQueeney',
    'Meadowlakes',
    'Melissa',
    'Mesquite',
    'Midland',
    'Midlothian',
    'Missouri City',
    'Mont Belvieu',
    'Montgomery',
    'Mustang Ridge',
    'Navasota',
    'Needville',
    'Nevada',
    'New Berlin',
    'New Braunfels',
    'Niederwald',
    'Nolanville',
    'North Richland Hills',
    'Northlake',
    'Oak Leaf',
    'Oak Point',
    'Oak Valley',
    'Odessa',
    'Old River Winfree',
    'Orange',
    'Ovilla',
    'Paloma Creek South',
    'Paradise',
    'Parker',
    'Pasadena',
    'Pattison',
    'Patton Village',
    'Pearland',
    'Pecan Acres',
    'Pecan Grove',
    'Pelican Bay',
    'Pendleton',
    'Pflugerville',
    'Pilot Point',
    'Pinehurst',
    'Pinehurst',
    'Plano',
    'Pleasanton',
    'Ponder',
    'Porter Heights',
    'Portland',
    'Princeton',
    'Prosper',
    'Providence Village',
    'Red Oak',
    'Reno',
    'Rhome',
    'Richardson',
    'Richland Hills',
    'Richmond',
    'Roanoke',
    'Robstown',
    'Rockdale',
    'Rockport',
    'Rockwall',
    'Roman Forest',
    'Rosenberg',
    'Rosharon',
    'Round Rock',
    'Rowlett',
    'Royse City',
    'Runaway Bay',
    'Sachse',
    'Saginaw',
    'Salado',
    'San Antonio',
    'San Marcos',
    'Sanger',
    'Santa Clara',
    'Santa Fe',
    'Santa Rita Ranch',
    'Scenic Oaks',
    'Schertz',
    'Seabrook',
    'Seagoville',
    'Sealy',
    'Seguin',
    'Selma',
    'Serenada',
    'Shady Shores',
    'Shavano Park',
    'Shenandoah',
    'Sherman',
    'Sienna',
    'Sinton',
    'Sonterra',
    'Sour Lake',
    'Southlake',
    'Southside Place',
    'Spring',
    'Springtown',
    'Stafford',
    'Sugar Land',
    'Sunnyvale',
    'Taylor',
    'Taylor Lake Village',
    'Temple',
    'Terrell',
    'Texas City',
    'The Colony',
    'The Woodlands',
    'Thorndale',
    'Timberwood Park',
    'Tom Bean',
    'Tomball',
    'Travis Ranch',
    'Trophy Club',
    'Uhland',
    'Universal City',
    'Van Alstyne',
    'Venus',
    'Waco',
    'Waller',
    'Watauga',
    'Waxahachie',
    'Weatherford',
    'Webster',
    'Weston',
    'Weston Lakes',
    'Westworth Village',
    'Willis',
    'Willow Park',
    'Wolfforth',
    'Woodbranch',
    'Wylie'
  ],
  'Panama Province': ['Panama City'],
  'Quintana Roo': ['Tulum']
}
