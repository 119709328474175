import React, { useState, useEffect } from 'react'
import createAxiosInstance from '../action/apiConfig'
// import axios from 'axios'
import { useParams } from 'react-router-dom'
import Loader from '../components/Shared/Loader'
import SingleImageEdit from '../components/EditImage/SingleImageEdit'
import MultiImageEdit from '../components/EditImage/MultiImageEdit'

const EditImages = ({ setrerenderComp, rerenderComp }) => {
  // const [isPriceHave, setIsPriceHave] = useState(true)
  const [errors, seterrors] = useState(null)
  const [showErrors, setshowErrors] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    location: '',
    city: '',
    state: '',
    country: '',
    coordinates: {
      coordinates: [0, 0]
    },
    type: '',
    tags: '',
    coverImage: '',
    images: [], // Initialize images as an empty array
    floorPlan: [],
    video: '',
    seoTitle: '',
    seoDes: '',
    seoKeywords: '',
    description: '',
    slug: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [newCoverImage, setnewCoverImage] = useState('')
  const [newImages, setnewImages] = useState([])
  const [rerender, setrerender] = useState(false)
  const [trigger, settrigger] = useState(0)
  const { id } = useParams()

  const axiosInstance = createAxiosInstance()
  // console.log('xxxxx')
  useEffect(() => {
    async function fetchData() {
      // setIsLoading(true)
      await axiosInstance
        .get(`/products/single-product/${id}`)
        .then((response) => {
          // console.log(response.data)
          const productData = response.data
          // setcoverImage(productData.coverImage.split('.').slice(0, -1).join('.') || '')
          setFormData({
            name: productData.name || '',
            price: productData.price || '',
            location: productData.location || '',
            city: productData.city || 'Select City',
            state: productData.state || 'Select State',
            country: productData.country || 'Select Country',
            coordinates: {
              coordinates: productData?.coordinates?.coordinates || [0, 0]
            },
            type: productData.type || '',
            tags: productData.tags || '',
            coverImage: productData.coverImage || '',
            images: productData.images || [],
            floorPlan: productData.floorPlan || [],
            video: productData.video || '',
            seoTitle: productData.seoTitle || '',
            seoDes: productData.seoDes || '',
            seoKeywords: productData.seoKeywords || '',
            description: productData.description || '',
            slug: productData.slug || ''
          })
          setnewImages(productData.images || [])
          setnewCoverImage(productData.coverImage || '')
        })
        .catch((error) => {
          console.log(error)
        })
    }
    fetchData()
  }, [id, rerenderComp])

  if (isLoading) {
    return <Loader />
  }
  function handleAutofill() {
    settrigger((st) => st + 1)
    let arr = []
    formData.images.map((m, i) => {
      arr.push(`${formData.slug}-${i + 1}.${m.split('.').splice(-1)}`)
    })
    // setnewCoverImage(`${formData.slug}.${formData.coverImage.split('.').splice(-1)}`)
    setnewImages(arr)
  }
  function setimages(index, value) {
    const imageList = [...newImages]
    imageList[index] = value
    setnewImages(() => [...imageList])
  }
  async function handleSubmit() {
    const arr = [{ oldName: formData.coverImage, newName: newCoverImage }]
    Array.from({ length: formData.images.length }).map((m, i) => {
      arr.push({
        oldName: formData.images[i],
        newName: newImages[i]
      })
    })
    // console.log(arr)
    try {
      const res = await axiosInstance.post('/file-upload/rename-all', {
        allFiles: arr
      })
      if (res.status === 200 && res.data.status === 'renamed') {
        seterrors(null)
        try {
          const data = { ...formData, coverImage: newCoverImage, images: newImages }
          const response = await axiosInstance.put(`/products/edit/${id}`, data)
          if (response.status === 200 && response.data.message === 'Product updated successfully') {
            setrerenderComp((st) => !st)
          }
          // console.log(data)
          // throw new Error('error')
        } catch (error) {
          console.log('Error while updating product:', { error })
          const arr2 = []
          res.data.data.map((m, i) => {
            arr2.push({
              newName: m.oldName,
              oldName: m.newName
            })
          })
          const res2 = await axiosInstance.post('/file-upload/rename-all', {
            allFiles: arr2
          })
        }
      }
    } catch (error) {
      seterrors(error.response.data)
      setshowErrors(true)
      setTimeout(() => {
        setshowErrors(false)
      }, 4000)
      console.log(error)
    }
  }

  return (
    <div className="w-full h-full">
      {errors && showErrors && (
        <p className="fixed bottom-[10px] right-[30px] py-2 px-4 bg-red-500 text-white">{errors.message}</p>
      )}
      <div action="" className="w-full h-full">
        <div className="flex mb-5 justify-between items-center">
          <h1 className="text-4xl font-bold">Edit Product Images</h1>
          <button
            onClick={handleAutofill}
            className="px-5 rounded-lg py-2 bg-blue-500 text-white font-bold hover:bg-blue-700"
          >
            AUTOFILL
          </button>
        </div>
        <h3 className="text-blue-900 font-semibold text-2xl text-center">Product Basic Details </h3>
        <div className="grid grid-cols-1 gap-5 my-5 lg:grid-cols-2">
          <div className="w-full">
            <label htmlFor="" className="block">
              Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter the product name"
              className="block w-full form-input rounded-md border-gray-500"
              name="name"
              value={formData.name}
              disabled
            />
          </div>
          <div className="w-full">
            <label htmlFor="" className="block">
              Price <span className="text-red-600">*</span>
            </label>
            <div className="grid grid-cols-[160px,auto] gap-2 items-center">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">$</div>
                <input
                  type="text"
                  className="border-gray-500 form-input rounded-md block w-full pl-10"
                  placeholder="Enter the product price"
                  name="price"
                  value={formData.price}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Property Type <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="type"
            className="block w-full form-input rounded-md border-gray-500"
            disabled
            placeholder="Enter the property type"
            value={formData.type}
          />
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Slug <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="slug"
            className="block w-full rounded-md border-gray-500 outline-none focus:border-green-600"
            placeholder="Enter the page slug"
            value={formData.slug}
            disabled
          />
        </div>
        {formData.coverImage && (
          <SingleImageEdit
            duplicates={errors?.alreadyExist}
            trigger={trigger}
            setnewCoverImage={setnewCoverImage}
            setrerender={setrerender}
            formData={formData}
            id={id}
          />
        )}
        {formData.images.length > 0 &&
          formData.images.map((m, i) => (
            <MultiImageEdit
              duplicates={errors?.alreadyExist}
              trigger={trigger}
              formData={formData}
              image={m}
              index={i}
              key={i}
              id={id}
              setimages={setimages}
              setrerender={setrerender}
            />
          ))}
        <div className="w-full flex justify-center mt-4">
          <button
            onClick={handleSubmit}
            className="bg-green-500 hover:bg-green-700 px-4 flex py-2 text-center rounded-md justify-center uppercase font-bold text-white"
          >
            Rename All
          </button>
        </div>
        <br />
      </div>
    </div>
  )
}

export default EditImages
